import React from "react";
import Header from "./Header";
import Navbar from "./Navbar";
import Hotelindustry from "./hotel industry.jpg";
import vision from "./vision.jpg";
import mission from "./mission.jpg";
import Footer from "./Footer";
import Edge from "./edge_technology.jpg";
import ScrollToTop from "./ScrollToTop";
import { Helmet } from "react-helmet";

const Aboutus =()=> {
    return(
        <>
        <Helmet>
            <title>  About Us | MacoTech: India’s Best Hotel Management Software  </title>
            <meta name="description" content="Learn about MacoTech mission, vision, and core values. Discover why MacoTech is the right choice and what sets us apart from the competition."/>
            <meta name="robots" content="index, follow"/>
            
            <meta property="og:type" content="website"/>
<meta property="og:title" content="About Us | Macotech: India’s Best Hotel Management Solution"/>
<meta property="og:url" content="https://macotech.in/aboutus"/>
<meta property="og:image" content="https://macotech.in/MACO_favicon.png"/>
<meta property="og:description" content="Learn about MacoTech mission, vision, and core values. Discover why Macotech is the right choice and what sets us apart from the competition." />

<meta name="twitter:card" content="summary"/>
<meta name="twitter:title" content="About Us | Macotech: India’s Best Hotel Management Solution"/>
<meta name="twitter:site" content="@MacoTechLtd"/>
<meta name="twitter:description" content="Learn about MacoTech mission, vision, and core values. Discover why Macotech is the right choice and what sets us apart from the competition."/>
<meta name="twitter:image" content="https://macotech.in/MACO_favicon.png"/>
<meta name="twitter:image:alt" content="Macotech | Best Hotel Management System"/>

            <link rel="canonical" href="https://macotech.in/aboutus" />

            <script type="application/ld+json">
          {`
{
	"@context": "http://schema.org/",
	"@type": "Organization",
	"name": "MacoTech",
	"logo": "https://macotech.in/static/media/Logo.6f2024a76448a5497e9d.png",
	"url": "https://macotech.in/",
	"address": {
		"@type": "PostalAddress",
		"streetAddress": "First Floor, Bungalow Plot 2, West Patel Nagar",
		"addressLocality": "New Delhi",
		"addressRegion": "Delhi",
		"postalCode": "110008",
		"addressCountry": "India"
	},
	"sameAs": ["https://www.facebook.com/macotechsoftware/", "https://www.instagram.com/macotechsoftware/", "https://www.linkedin.com/company/macotechsoftware/", "https://x.com/macotechin"]
}

  `}
  
</script>

        </Helmet>

         <Header/>
         <Navbar />

         
        <div className="aboutus-container">
        <div>
        <h1 class='service-heading'>About Us</h1>
        </div>
        </div>
        <div className="aboutus-sec1">
            <div className="aboutus-image1">
            <img src={Hotelindustry} alt="Macotech Hotel Industry" className="about-hotel-img"></img>
            </div>
            <div className="aboutus-content1">
            <h2>Who We Are</h2>
             <h3>Macotech Software Pvt. Ltd. is a part of Maco Group of Companies established in 2009. With over a decade of experience, we have been at the forefront of innovation, providing cutting-edge software solutions designed to streamline hotel management and enhance operational efficiency.
             </h3>
            </div>
        </div>
        <div className="aboutus-sec2">
             <div className="aboutus-content1">
             <h2>Our Vision</h2>
             <h3>To revolutionize the way hotels operate with our advanced hotel management solutions. With a passion for innovation and a deep understanding of the hospitality industry, we’ve developed a powerful  Cloud Based “Hotel Manager” Software, which is a user-friendly platform that streamlines daily operations, enhances guest satisfaction, and drives business growth.
             </h3>
            </div>
            <div className="aboutus-image1">
            <img src={vision} alt="Macotech Vision" className="about-vision-img"></img>
            </div>
           
        </div>
        <div className="aboutus-sec2copy">
            <div className="aboutus-image1">
            <img src={vision} alt="Macotech Vision" className="about-vision-img"></img>
            </div>
             <div className="aboutus-content1">
             <h2>Our Vision</h2>
             <h3>To revolutionize the way hotels operate with our advanced hotel management solutions. With a passion for innovation and a deep understanding of the hospitality industry, we’ve developed a powerful  Cloud Based “Hotel Manager” Software, which is a user-friendly platform that streamlines daily operations, enhances guest satisfaction, and drives business growth.
             </h3>
            </div>       
           
        </div>
        <div className="aboutus-sec3">
            <div className="aboutus-image1">
            <img src={mission} alt="Macotech Mission" className="about-mission-img"></img>
            </div>
            <div className="aboutus-content1">
            <h2>Our Mission</h2>
             <h3>To empower hotels of all sizes  boutique  or large chains with the tools they need to manage bookings, automate processes, and optimize resources. From front-desk operations to housekeeping, guest communication, and data-driven decision-making, Macotech offers a comprehensive suite of features that simplify the complexities of hospitality management.
             </h3>
            </div>
        </div>
        <div className="aboutus-sec3copy">
            <div className="aboutus-image1">
            <img src={mission} alt="Macotech Mission" className="about-mission-img"></img>
            </div>
            <div className="aboutus-content1">
            <h2>Our Mission</h2>
             <h3>To empower hotels of all sizes  boutique  or large chains with the tools they need to manage bookings, automate processes, and optimize resources. From front-desk operations to housekeeping, guest communication, and data-driven decision-making, Macotech offers a comprehensive suite of features that simplify the complexities of hospitality management.
             </h3>
            </div>
        </div>
      
        <div className="about-us-cover">
        <img src={Edge} alt="Macotech Cutting Edge Technology"></img>
        <h2>With cutting-edge technology, cloud solutions and real-time analytics, ensure our clients stay ahead in the competitive landscape. Hotel Manager is designed to scale with your business, providing flexibility, efficiency, and a superior guest experience.</h2>
        </div>
        <ScrollToTop/>
        <Footer/>
        </>
    )
}

export default Aboutus ;