import React, { useEffect, useState } from 'react';
import './style.css';
import client1 from './clientimage/client1.png';
import client2 from './clientimage/client2.png';
import client3 from './clientimage/client3.png';
import client4 from './clientimage/client4.png';
import client5 from './clientimage/client5.png';
import client6 from './clientimage/client6.png';
import client7 from './clientimage/client7.png';
import client8 from './clientimage/client8.png';
import client9 from './clientimage/client9.png';
import client10 from './clientimage/client10.png';
import client11 from './clientimage/client11.png';
import client12 from './clientimage/client12.png';
import client13 from './clientimage/client13.png';
import client14 from './clientimage/client14.png';
import client15 from './clientimage/client15.png';
import client16 from './clientimage/client16.png';
import client17 from './clientimage/client17.png';
import client18 from './clientimage/client18.png';
import client19 from './clientimage/client19.png';
import client20 from './clientimage/client20.png';
import client21 from './clientimage/client21.png';
import client22 from './clientimage/client22.png';
import client23 from './clientimage/client23.png';
import client24 from './clientimage/client24.png';
import client25 from './clientimage/client25.png';





const clients = [
  { name: 'Client 1', image: client1 },
  { name: 'Client 2', image: client2 },
  { name: 'Client 3', image: client3 },
  { name: 'Client 4', image: client4 },
  { name: 'Client 5', image: client5 },
  { name: 'Client 6', image: client6 },
  { name: 'Client 7', image: client7 },
  { name: 'Client 8', image: client8 },
  { name: 'Client 9', image: client9 },
  { name: 'Client 10', image: client10 },
  { name: 'Client 11', image: client11 },
  { name: 'Client 12', image: client12 },
  { name: 'Client 13', image: client13 },
  { name: 'Client 14', image: client14 },
  { name: 'Client 15', image: client15 },
  { name: 'Client 16', image: client16 },
  { name: 'Client 17', image: client17 },
  { name: 'Client 18', image: client18 },
  { name: 'Client 19', image: client19 },
  { name: 'Client 20', image: client20 },
  { name: 'Client 21', image: client21 },
  { name: 'Client 22', image: client22 },
  { name: 'Client 23', image: client23 },
  { name: 'Client 24', image: client24},
  { name: 'Client 25', image: client25 }
];

const Clientcarousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);


  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % (clients.length));
    }, 1000);

    return () => clearInterval(interval);
  }, []);


  const handlePrevClick = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 3 + clients.length) % clients.length);
  };

  const handleNextClick = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 3) % clients.length);
  };

  return (
    <div className="client-logo-slider">
      <button className="prev" onClick={handlePrevClick}>❮</button>
      <div className="client-carousel">
        <div className="client-track" style={{ transform: `translateX(-${currentSlide * (400 / clients.length)}%)` }}>
          {clients.map((client, index) => (
            <div key={index} className="client">
              <img src={client.image} alt={client.name} />
            </div>
          ))}
        </div>
      </div>
      <button className="next" onClick={handleNextClick}>❯</button>
    </div>
  );
};

export default Clientcarousel;
