import React, { useState } from 'react';
import './style.css';

const questions = [
  { question: 'What is Hotel Manager?', answer: 'Hotel Manager is a comprehensive hotel management software designed to streamline hotel operations, enhance guest experience, and boost revenue.' },
  { question: 'How can Hotel Manager benefit my hotel?', answer: 'Hotel Manager improves efficiency, enhances guest satisfaction, fast check-in/check-out and provides real-time data and analytics for better decision-making.' },
  { question: ' Is Hotel Manager suitable for small hotels?', answer: 'Yes, Hotel Manager is scalable and can be tailored to suit small boutique hotels as well as large hotel chains.' },
  { question: ' Can I manage multiple properties with Hotel Manager?', answer: 'Yes, Hotel Manager allows you to manage multiple hotel properties from a single login.' },
  { question: 'Is there a mobile app available for Hotel Manager?', answer: 'Yes, Hotel Manager offers a mobile app for MIS Reporting to Admin / Owner at Additional Cost ' },
  { question: ' Can I send automated SMS and email notifications to guests?', answer: 'Yes, Hotel Manager allows you to send automated booking confirmations, check-in, and check-out notifications via SMS and email at Additional Cost  ' },
  { question: 'What kind of customer support does Hotel Manager offer?', answer: 'Hotel Manager provides customer support to address any issues between 11:00 AM To 6:00 PM ( Monday To Friday )' },
  { question: 'Is training provided for using Hotel Manager?', answer: 'Yes, we offer comprehensive training to ensure that your staff can effectively use the software.' },
  { question: "Can I customize Hotel Manager to fit my hotel's specific needs?", answer: 'Yes, Hotel Manager is highly customizable to meet the unique requirements of your hotel at Additional Cost ' },
  { question: 'How do I get started with Hotel Manager ?', answer: 'Contact us to schedule a demo and learn more about how Hotel Manager can transform your hotel operations.' },
];

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleQuestion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    
    <div className="faq-container">
      {/* <h2>Frequently Asked Questions</h2> */}
      <div className="faq-items">
        {questions.map((item, index) => (
          <div className="faq-item" key={index}>
            <div className="faq-question" onClick={() => toggleQuestion(index)}>
              {item.question}
              <span className="faq-icon">{activeIndex === index ? '-' : '+'}</span>
            </div>
            <div className={`faq-answer ${activeIndex === index ? 'active' : ''}`}>
              {item.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
