import Reservation from './images/icon_1.png';
import Booking from './images/bookings.png';
import Account from './images/Accounts.png';
import Checkin from './images/checkin.png';
import Dashboard from './images/Dashboard.png';
import Housekeeping from './images/Housekeeping.png';
import Channel from './images/channels.png';
import Pending from './images/pending.png';
import Roomservice from './images/Room_service.png';
import RoomManagement from './images/roommgt.png';
import Search from './images/search.png';
import Mobileapp from './images/MobileApp.png' ;
import Banquet from './images/banquet.png';
import Report from './images/report.png';
import Restaurant from './images/restro.png';
import Website from './images/websitebook.png';




const Fdata=[
    { 
        img:Dashboard,
        desc:"Dashboard",
        heading:"Dashboard",
        para:"Access real-time data and analytics from a customizable dashboard"
       
    },
    {
        img: Reservation,
        desc:'Reservation',
        heading:"Reservation",
        para:"Effortlessly manage bookings with a user-friendly reservation system" 
        
    },
    {
        img:Checkin,
        desc:"Checkin",
        heading:"Check In / Check Out",
        para:"Streamline guest check-in and check-out processes for a better experience"
       
    },
    {
        img:Roomservice,
        desc:"Roomservice",
        heading:"Room Service",
        para:"Manage and track room service orders efficiently"
       
    },
    {
        img:RoomManagement,
        desc:"RoomManagement",
        heading:"Room Inventory",
        para:"Efficiently manage room inventory, including availability, maintenance, and room status updates"
       
    },
    {
        img:Housekeeping,
        desc:"Housekeeping",
        heading:"Housekeeping",
        para:"Automate housekeeping tasks and monitor room status in real time"
       
    },
    {
        img:Channel,
        desc:"Comission",
        heading:"Channel Manager Integration",
        para:" channel manager integration for increased bookings and streamlined management."
       
       
    },
    {
        img:Account,
        desc:"Account",
        heading:"Accounts Payment & Receipt",
        para:"Handle financial transactions with ease, ensuring accuracy"
        
    },
    {
       img:Booking,
        desc:"Booking",
        heading:"OTA Booking",
        para:"Integrate seamlessly with online travel agencies for increased bookings"
    },
    {
        img:Search,
        desc:"Search",
        heading:"Smart Search Panel",
        para:"Quickly find and manage reservations and guest information"
    },
    {
        img:Mobileapp,
        desc:"Mobileapp",
        heading:"Mobile App",
        para:"Mobile app for useful information on real-time basis to admin / owner."
    },
    {
        img:Pending,
        desc:"Pending",
        heading:"Pending Check-In & Check-Out Reminder",
        para:"Receive reminders for pending guest check-ins and check-outs"
    },
    {
        img:Banquet,
        desc:"Banquet",
        heading:"Banquet Management",
        para:"Organize and manage banquet events and bookings seamlessly"
    },
    {
        img:Restaurant,
        desc:"Restaurant",
        heading:"Restaurant Management",
        para:"Simplify operations and enhance service with our restaurant management feature."
    },
    {
        img:Website,
        desc:"Website Booking",
        heading:"Website Booking Engine",
        para:" online reservations with our integrated website booking engine."
    },
    {
        img:Report,
        desc:"Report",
        heading:"Reports",
        para:"Generate detailed reports on occupancy, revenue, and operational performance"
    }
    

]



 export default Fdata;