import React from 'react'; 



const Benefit = (props) => {
    return(
        <>
        <div className='feature-card' >
            <img src={props.img} alt={props.desc}></img>
            <div>
            <h2>{props.heading}</h2>
            <p>{props.para}</p>
            </div>
        </div>


        </>
    );
}

export default Benefit ;