import React from 'react';
import { Link } from 'react-router-dom';

const Thankyoupage = () => {
  return (
    <>
    <div className='Thankyou-container'>
    <div className='thankyou-header'></div>
    <div className='thankyou-msg'>
      <h1>Thank You!</h1>
      <p>Your form has been submitted successfully.</p>
      <Link to='/'><button>Go Home</button></Link>
    </div>
    </div>
    </>
    );
};

export default Thankyoupage;