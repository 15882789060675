import Mobileapp from './images/MobileApp.png';
import WebsiteBooking from './images/Websitebooking.png';
import Channelmanager from './images/channelmanager.png';
import Websitecontent from './images/websitecontent.png';
import SMSemail from './images/sms.png';
import Multihotel from './images/multihotel.png';
import GSTreport from './images/GSt.png';
import Scalability from './images/scalability.png';



const Bdata=[
    { 
        img: Mobileapp,
        desc:'Mobileapp',
        heading:"Mobile App ",
        para:"MIS reporting on mobile for real-time admin/owner insights."
    },
    {
        img:WebsiteBooking,
        desc:"WebsiteBooking",
        heading:"Website Booking Engine",
        para:"Enable direct bookings from your website, increasing revenue"
    },
    {
        img:Channelmanager,
        desc:"Channelmanager",
        heading:"Channel Manager ",
        para:"Synchronize room availability and rates across multiple booking channels"
    },
    {
        img:Websitecontent,
        desc:"Websitecontent",
        heading:"Website Content Management System",
        para:"Easily update and manage your website content"
    },
    {
        img:SMSemail ,
        desc:"SMSemail ",
        heading:"SMS / Email ",
        para:"Send automated booking confirmations and updates via SMS and email"
    },
    {
        img:Multihotel,
        desc:"Multihotel",
        heading:"Multiple Hotel Access",
        para:"Manage multiple hotel properties from a single login"
    },
    
    {
        img:GSTreport,
        desc:"GST",
        heading:"Accounts and GST Reports",
        para:"Generate accurate P&L, Balance Sheet, Trial Balance  and GST reports"
    },
    {
        img:Scalability,
        desc:"Scalability",
        heading:"Scalability",
        para:"Adapt to your growing business needs effortlessly"
    }
    

]



 export default Bdata;